import { createContext, useState } from 'react'
import { useRoutes } from 'react-router-dom';
import router from 'src/router';
import { IntlProvider } from 'react-intl';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { messages, supportedLanguages } from './translations'

export const LocalizationContext = createContext({
  userLanguage: '',
  setLanguage: (newLanguage: string) => {}
});

function App() {

  const content = useRoutes(router);
  const [userLanguage, setUserLanguage] = useState('en');

  const setLanguage = (language) => {
    if (Object.keys(supportedLanguages).includes(language)) {
      setUserLanguage(language);
    }
  };

  return (
    <ThemeProvider>
      <CssBaseline />
      <LocalizationContext.Provider value={{userLanguage, setLanguage}}>
        <IntlProvider locale={userLanguage} messages={messages[userLanguage]}>
          {content}
        </IntlProvider>
      </LocalizationContext.Provider>
    </ThemeProvider>
  );
}
export default App;
