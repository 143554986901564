import { RouteObject } from 'react-router';
import { Loader } from 'src/components/SuspenseLoader/Loader';
import { lazy } from 'react';

const VerifyAccount = Loader(lazy(() => import('src/content/public/VerifyAccount')));
const ResetPword = Loader(lazy(() => import('src/content/public/ResetPword')));
const Status404 = Loader(lazy(() => import('src/layouts/Status404')));

const routes: RouteObject[] = [
  { path: `/verify/:lang/:token`, element: <VerifyAccount /> },
  { path: `/reset-my-password/:lang/:type/:token`, element: <ResetPword /> },
  { path: '/404', element: <Status404 /> }
];

export default routes;
